<template>
  <v-form
    id="category-form"
  >
    <v-expansion-panels mandatory>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('basicInfo') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            :label="$t('name') + ' *'"
            v-model="value.name"
            :disabled="processing"
            validate-on-blur
            :rules="[rules.required, rules.whiteSpaceString, rules.trailingWhiteSpace]"
          />
          <v-textarea
            :label="$t('description')"
            :disabled="processing"
            filled
            v-model="descriptionValue"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('settings') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                :label="$t('sortingWeight')"
                type="number"
                v-model="value.weight"
                :disabled="processing"
                prepend-icon="mdi-sort"
                persistent-hint
                :hint="$t('categorySortingWeightHint')"
              />
            </v-col>
            <v-col
              v-if="false"
              cols="12"
            >
              <v-switch
                :label="$t('Required')"
                v-model="value.required"
                :disabled="processing || true"
                persistent-hint
                :hint="$t('categoryRequiredHint')"
              />
            </v-col>
            <v-col
              v-if="false"
              cols="12"
            >
              <v-switch
                :label="$t('single')"
                v-model="value.single"
                :disabled="processing || true"
                persistent-hint
                :hint="$t('categorySingleHint')"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                :label="$t('administrative')"
                v-model="value.administrative"
                :disabled="processing"
                persistent-hint
                :hint="$t('categoryAdministrativeHint')"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                :label="$t('allowSplitInHalf')"
                v-model="value.allowSplitByHalf"
                :disabled="processing"
                persistent-hint
                :hint="$t('categoryAllowSplitInHalfHint')"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header> {{ $t('advancedSettings') }} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('accountingKey')"
                v-model="value.accountingKey"
                :disabled="processing"
                prepend-icon="mdi-bank"
                persistent-hint
                :hint="$t('categoryAccountingKeyHint')"
                :placeholder="$t('categoryAccountingKeyPlaceholder')"
              />
            </v-col>
            <v-col
              v-if="false"
              cols="12"
            >
              <v-text-field
                :label="$t('effectiveProcessingTime')"
                type="number"
                v-model="value.ept"
                :disabled="processing || true"
                prepend-icon="mdi-clock"
                persistent-hint
                :hint="$t('categoryEptHint')"
                :placeholder="$t('categoryEptPlaceholder')"
              />
            </v-col>
            <v-col cols="12">
              <v-menu
                offset-y
                :close-on-content-click="false"
                max-width="500"
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-text-field
                    v-on="on"
                    v-bind="attrs"
                    :label="$t('primaryColor')"
                    :placeholder="$t('categoryPrimaryColorPlaceholder')"
                    v-model="value.primary_color"
                    :disabled="processing"
                    persistent-hint
                    prepend-icon="mdi-palette"
                    clearable
                    :hint="$t('categoryPrimaryColorHint')"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        v-if="value.primary_color"
                        :color="value.primary_color"
                      >
                        mdi-circle
                      </v-icon>
                      <v-icon v-else>
                        mdi-circle-outline
                      </v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-color-picker
                  width="500"
                  mode="hexa"
                  hide-mode-switch
                  v-model="value.primary_color"
                  @update:color="selectColor"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('parentCategories') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                :label="$t('parentCategories')"
                chips
                v-model="value.parent"
                :items="categories"
                item-text="name"
                item-value="uuid"
                return-object
                multiple
                :disabled="processing"
                deletable-chips
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('categoryImage') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col cols="12">
              <v-alert
                type="warning"
                icon="mdi-alert"
              >
                {{ $t('currentlyDisabled') }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('categoryImage')"
                prepend-icon="mdi-file-upload"
                :placeholder="$t('categoryImagePlaceholder')"
                persistent-hint
                :hint="$t('categoryImageHint')"
                disabled
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                large
                color="primary"
                disabled
              >
                {{ $t('upload') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>{{ $t('openingHours') }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row dense>
            <v-col
              cols="12"
              class="pb-3"
            >
              <span class="caption"><v-icon small>mdi-information</v-icon> <span style="vertical-align: middle">{{ $t('categoryOpeningHoursDescription') }}</span></span>
            </v-col>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="value.opening_rule"
                  :items="openingRules"
                  item-text="text"
                  item-value="value"
                  :label="$t('openingRule')"
                  required
                  filled
                  dense
                  disabled
                />
              </v-col>
            </v-row>
            <OpeningHours
              :opening-rule="value.opening_rule"
              :opening-hours="value.opening_hours"
              @addNewOpeningHour="addNewOpeningHour"
              @sameAsPrevious="copyOpeningHoursFromPreviousWeekday"
              empty-is-default-open
            />
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-alert
      v-if="couldNotSaveChanges"
      v-model="couldNotSaveChanges"
      type="error"
      class="mb-0 mt-5"
      dismissible
    >
      <div>
        {{ $t('sorryUnableToSaveChanges') }}
      </div>
    </v-alert>
    <v-btn
      v-if="edit"
      :loading="processing"
      :disabled="processing || !requiredValuesFilled"
      class="mt-5"
      color="primary"
      x-large
      @click="save"
      block
    >
      {{ $t('save') }}
    </v-btn>
  </v-form>
</template>

<script>

import OpeningHours from "@/components/common/OpeningHours"
export default {
	name: 'CategoryForm',
	components: {
		OpeningHours
	},
  props: {
    value: {
      type: Object, 
      default: () => {}
    },
    edit: {
      type: Boolean
    }
  },
	data() { 
    return {
      processing: false,
      couldNotSaveChanges: false,
      hours: {
        open: null,
        closed: null,
      },
      openingRules: [
        {value: "category_is_open_based_on_opening_hours", text: "Category is open based on opening hours"},
        {value: "category_is_always_open", text: "Category is always open"},
        {value: "category_is_always_closed", text: "Category is always closed"}
      ],
      weekdays: [
        {
          number: 1,
          name: this.$t('monday'),
          openingHours: []
        },
        {
          number: 2,
          name: this.$t('tuesday'),
          openingHours: []
        },
        {
          number: 3,
          name: this.$t('wednesday'),
          openingHours: []
        },
        {
          number: 4,
          name: this.$t('thursday'),
          openingHours: []
        },
        {
          number: 5,
          name: this.$t('friday'),
          openingHours: []
        },
        {
          number: 6,
          name: this.$t('saturday'),
          openingHours: []
        },
        {
          number: 0,
          name: this.$t('sunday'),
          openingHours: []
        }
      ]
    }
	},
	computed: {
    descriptionValue: {
      get() {
        if (this.value?.description) {
          return this.value.description.replace(/<\/?[^>]+(>|$)/g, "")
        } 
        return ''
      },
      set(value) {
        this.value.description = value
      }
    },
		categories() {
			let categories = this.$store.getters.categories
			if(categories) {
				return this.$store.state.categories.filter(category => category.uuid !== this.value.uuid)
			} else {
				return []
			}
		}, 
    rules() {
      return this.$store.getters.rules
    },
    requiredValuesFilled() {
      const rules = this.$store.getters.rules 
      if(rules.required(this.value.name) === true && rules.whiteSpaceString(this.value.name) === true && rules.trailingWhiteSpace(this.value.name) === true) {
        return true
      }
      return false
    }
	},
	watch: {
	},
	methods: {
    copyOpeningHoursFromPreviousWeekday(weekday) {
      if (!this.value.opening_hours || !Array.isArray(this.value.opening_hours)) {
        return
      }
      const previousWeekday = weekday === '0' ? 6 : ((weekday * 1) - 1)
      const previousWeekdayOpeningHours = this.value.opening_hours.filter(openingHour => (openingHour.day * 1) === previousWeekday)
      this.value.opening_hours = this.value.opening_hours.filter(openingHour => (openingHour.day * 1) !== (weekday * 1))
      if(previousWeekdayOpeningHours.length > 0) {
        for(let i = 0; i < previousWeekdayOpeningHours.length; i++) {
          let openingHour = JSON.parse(JSON.stringify(previousWeekdayOpeningHours[i]))
          openingHour.day = weekday + ''
          this.value.opening_hours.push(openingHour)
        }
      }
    },
		addNewOpeningHour(openingHour) {
			if(!this.value.opening_hours || !Array.isArray(this.value.opening_hours) || this.value.opening_hours.length < 1) {
				this.value.opening_hours = []
			}
			this.value.opening_hours.push(openingHour)
		},
		addNewHourLine(weekday) {
			let weekdayLocation = this.weekdays.find(w => w.number === weekday.number)
			if(weekdayLocation) {
				weekdayLocation.openingHours.push(this.hours)
			}
		},
		selectColor(color) {
			this.value.primary_color = color.hex
		},
		create() {
			this.processing = true
			this.$store.dispatch('createCategory', this.value)
					.then(() => {
						this.$emit('created', this.value)
					})
					.finally(() => {
						this.processing = false
					})
		},
		save() {
			this.processing = true
      this.couldNotSaveChanges = false
			this.$store.dispatch('updateCategory', this.value)
					.then(() => {
						this.$emit('updated', this.value)
					})
					.catch(() => {
            this.couldNotSaveChanges = true
            this.$store.commit('updateActionError', {
              message: this.$t('error'),
              subMessage: this.$t('unableToSaveChanges')
            })
          })
					.finally(() => {
						this.processing = false
					})
		}
	}
}
</script>
<template>
  <v-row dense>
    <v-col
      cols="12"
      v-for="weekday in weekdayLabels"
      :key="weekday.day"
    >
      <OpeningHoursWeekday
        :weekday="weekday"
        v-model="openingHours"
        :opening-rule="openingRule"
        v-on="$listeners"
        :empty-is-default-open="emptyIsDefaultOpen"
      />
    </v-col>
  </v-row>
</template>

<script>
import OpeningHoursWeekday from "@/components/openingHours/OpeningHoursWeekday"

export default {
	name: 'OpeningHours',
	components: {
		OpeningHoursWeekday
	},
	props: {
		openingHours: {
			type: Array,
			default: () => []
		},
		openingRule: {
			type: String,
			default: ''
		},
    emptyIsDefaultOpen: {
      type: Boolean,
      default: false
    }
	},
	data() {
		return {
			timeIntervalsInMillis: [],
			hours: {
				open: null,
				closed: null,
			},
			weekdayLabels: [
				{
					day: '1',
					name: this.$t('monday')
				},
				{
					day: '2',
					name: this.$t('tuesday')
				},
				{
					day: '3',
					name: this.$t('wednesday')
				},
				{
					day: '4',
					name: this.$t('thursday')
				},
				{
					day: '5',
					name: this.$t('friday')
				},
				{
					day: '6',
					name: this.$t('saturday')
				},
				{
					day: '0',
					name: this.$t('sunday')
				}
			]
		}
	},
	computed: {
	},
	created() {
		let minutesInDay = []
		minutesInDay.push({value: null, text: '- ' + this.$t('noValue') + ' - '})

		for(let i = 0; i < 1440; i++) {
			let stringValue = ""
			let minutes = Math.floor(i % 60)
			let hours = Math.floor((i / 60) % 24)

			if(hours > 0){
				stringValue = hours + '  ' + this.$t('hoursAnd') + ' '
			}
			stringValue = stringValue + minutes + ' ' + this.$t('minutes')

			minutesInDay.push({value: i * 60000, text: stringValue})
		}
		this.timeIntervalsInMillis = minutesInDay
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		addNewHourLine(weekday) {
			this.openingHours.push({day: weekday, starthours: null, endhours: null})
		},
		openingHoursByWeekdays: function(weekdayIndex) {
			if(!this.openingHours || !Array.isArray(this.openingHours) || this.openingHours.length < 1) {
				return []
			}
			return this.openingHours.filter(weekday => weekday.day === weekdayIndex)
		},
		maxSalesToFloat() {
			this.location.maximumSalesPerSlot = this.location.maximumSalesPerSlot.replace(/[^\d.-]/g, '')
		},
		userIsAdmin () {
			return !!(this.$store.state.user.email.includes('@salescloud.is'))
		}
	}
}
</script>

<style scoped>

</style>
